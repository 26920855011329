import React, { FunctionComponent } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import type { StackNavigationProp } from '@react-navigation/stack';
import { Root } from '../screens/root/Root';
import { FormsScreen } from '../screens/forms/FormsScreen';
import { DocumentsScreen } from '../screens/documents/DocumentsScreen';
import { VideosScreen } from '../screens/videos/VideosScreen';

const Stack = createStackNavigator<RootNavigationParamList>();

export const RootNavigation: FunctionComponent<RootNavigationProps> = () => {
  return (
    <Stack.Navigator
      initialRouteName="redirect"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Group>
        <Stack.Screen
          name="redirect" // for default redirection logics
          component={Root}
          options={{ title: 'Redirecting' }}
        />
        <Stack.Screen
          name="forms"
          component={FormsScreen}
          options={{ title: 'Redirecting Forms' }}
        />
        <Stack.Screen
          name="documents"
          component={DocumentsScreen}
          options={{ title: 'Redirecting documents' }}
        />
        <Stack.Screen
          name="videos"
          component={VideosScreen}
          options={{ title: 'Redirecting videos' }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface RootNavigationProps {}

export type RootNavigationParamList = {
  redirect: undefined; // for default redirection logics
  forms: {
    form_id: string;
  };
  documents: undefined;
  videos: undefined;
};

export type RootStackNavigationProp =
  StackNavigationProp<RootNavigationParamList>;
